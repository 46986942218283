
import { Component, Vue } from 'vue-property-decorator'
import { DetailInfo } from '@/types/qualitySafety'
import UploadFile from '@/components/uploadFile/Index.vue'
import { FileInfo } from '@/types/common'

@Component({
  components: { UploadFile },
  filters: {
    previewList: function (value: FileInfo[]) {
      return value.map((item: { filePrefix: string; fileUrl: string }) => {
        return item.filePrefix + item.fileUrl
      })
    }
  }
})
export default class QualitySafetyAdd extends Vue {
  private formInfo: DetailInfo = {
    projectId: '',
    /** 项目名称 */
    projectName: '',
    /** 事件类型 */
    safetyTypeName: '',
    /** 事件详情 */
    safetyDetail: '',
    /** 事件位置 */
    occurAddr: '',
    /** 发生时间 */
    occurDate: '',
    /** 负责人姓名 */
    leaderName: '',
    resourceList: []
  }

  private personList = []

  get safetyId () {
    return this.$route.params.safetyId as string || ''
  }

  created () {
    this.getDetail()
  }

  getDetail () {
    this.$axios.get(this.$apis.qualitySafety.selectQualitySafetyBySafetyId, { safetyId: this.safetyId }).then((res) => {
      this.formInfo = res || {}
    })
  }
}
